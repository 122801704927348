import { DISCORD_INVITE_URL } from "./help";

function FooterLink({ href, children }: { href: string; children: React.ReactNode }) {
  return (
    <a href={href} className="underline hover:text-foreground">
      {children}
    </a>
  );
}

export function Footer() {
  return (
    <div className="content-w mx-auto px-6 shrink-0">
      <div className="h-12 border-top flex flex-row items-center justify-center gap-2 text-sm text-muted-foreground border-t border-divider">
        <ul className="flex flex-row *:px-2 *:not-last:border-r *:not-last:border-r-divider px-4">
          {/* <li>
          <FooterLink href={DISCORD_INVITE_URL}>Discord</FooterLink>
        </li> */}
          <li>
            Made by <FooterLink href="https://whimsical.com">Whimsical</FooterLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

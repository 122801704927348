/**
 * Public home page
 */
import { Link, useLoaderData } from "react-router";
import { getSourceSummary } from "~/.server/source-summary";
import { Footer } from "~/components/footer";
import { publicPathSourceIds, sourceIdPublicPaths } from "~/model/source";
import { getSourceMeta } from "~/sources/config";
import { ItemIcon, SectionSymbol } from "../source/section";

import { useUser } from "../with-user";
export async function loader() {
  const sumcasts = (
    await Promise.all([
      getSourceSummary({ publicSourceId: publicPathSourceIds["/hacker-news"] }),
      getSourceSummary({ publicSourceId: publicPathSourceIds["/reddit/science"] }),
    ])
  ).map((sourceSummary) => {
    const meta = getSourceMeta(sourceSummary.source.sourceType);
    const summary = sourceSummary.summaries[0];
    return {
      sourceId: sourceSummary.source.sourceId,
      sourceType: sourceSummary.source.sourceType,
      sourceName: sourceSummary.source.sourceName,
      path: sourceIdPublicPaths[sourceSummary.source.sourceId],
      icon: meta.iconMono,
      summary: {
        symbol: summary.symbol,
        name: summary.name,
        items: summary.items,
      },
    };
  });
  return { sumcasts };
}

function HomeHeader() {
  const user = useUser();
  return (
    <div className="w-full sticky top-0">
      <div className="flex flex-row items-center px-7 h-14">
        <div className="grow" />
        <Link
          to={user ? "/" : "/signin"}
          className="text-sm text-primary font-semibold border border-primary/50 block px-4 py-1 rounded-md hover:bg-primary hover:text-primary-foreground focusable"
        >
          {user ? "Go to app" : "Sign in"}
        </Link>
      </div>
    </div>
  );
}

function Content() {
  const { sumcasts } = useLoaderData<typeof loader>();
  return (
    <ul className="self-stretch grid grid-cols-3 gap-4">
      {sumcasts.map((sumcast) => (
        <li key={sumcast.sourceId}>
          <Link
            to={sumcast.path}
            className="w-full h-44 rounded-sm bg-popover hover:bg-accent shadow-md focusable flex flex-col overflow-hidden border relative border-divider/50 group"
          >
            {/* source name */}
            <span className="mt-2 ml-1 font-serif font-semibold text-lg whitespace-nowrap uppercase  pr-2 py-0.5 text-primary">
              <span className="inline-flex items-center justify-center w-8">
                <img src={sumcast.icon} alt={sumcast.sourceName} />
              </span>
              {sumcast.sourceName}
            </span>
            {/* summary title */}
            <span className="ml-1 whitespace-nowrap font-serif font-semibold">
              <SectionSymbol symbol={sumcast.summary.symbol} />
              {sumcast.summary.name}
            </span>
            {/* summary item */}
            {sumcast.summary.items.map((item, index) => (
              <span key={index} className="text-sm ml-1 mt-1 whitespace-nowrap">
                <span className="inline-flex w-8 pl-2.5 relative top-[0.2rem]">
                  <ItemIcon type={item.type} />
                </span>
                <span className="inline-flex">
                  {item.title && <span className="font-semibold mr-1">{item.title}:</span>}
                  {item.summary}
                </span>
              </span>
            ))}
            <div className="absolute top-0 right-0 w-4 bg-gradient-to-l from-popover to-transparent h-full group-hover:from-accent" />
          </Link>
        </li>
      ))}
      <CreateYourOwn />
    </ul>
  );
}

function CreateYourOwn() {
  return (
    <li>
      <Link
        to="/signin"
        className="bg-primary text-primary-foreground shadow-md w-full h-full rounded-sm focusable flex items-center justify-center hover:bg-primary/90 text-center text-xl"
      >
        <span>
          Create your own,
          <br />
          <span className="font-semibold italic">personal</span> Sumcast
        </span>
      </Link>
    </li>
  );
}

function Icon({ src, alt }: { src: string; alt?: string }) {
  return <img src={src} alt={alt} className="w-4 h-4 mb-0.5 mx-0.5 inline-block" />;
}

export default function Home() {
  return (
    <div className="h-full w-full flex flex-col">
      <HomeHeader />
      <div className="grow flex flex-col">
        <div className="grow flex flex-col items-center justify-center px-4 pb-16 max-w-[52rem] max-h-[50rem] mx-auto">
          <img src="/images/logo-primary-32.svg" alt="Sumcast" className="mb-8" />
          <h1 className="mb-8 text-center font-serif text-lg font-semibold border-t border-b border-divider p-4 tracking-wide ">
            {/* Personalized summary of discussions and news */}
            Discussions, summarized
          </h1>
          <p className="mb-16 text-center text-muted-foreground">
            Sumcast distills <Icon src="/images/slack.svg" />
            Slack conversations,{" "}
            <a
              className="underline underline-offset-2 decoration-foreground/20 hover:decoration-foreground"
              href="https://whimsical.com/posts"
            >
              <Icon src="/images/whimsical.svg" />
              Whimsical posts
            </a>
            , <Icon src="/images/reddit.svg" />
            Reddit discussions and{" "}
            {/*<Icon src="/images/hn.svg" alt="Hacker News" />
              <Icon src="/images/bsky.svg" alt="Bluesky" />*/}
            more into a concise daily newsletter that only takes a few minutes to read. Or, listen to it in your
            favorite podcast app.
          </p>
          <Content />
        </div>
      </div>
      <Footer />
    </div>
  );
}
